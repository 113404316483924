import { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import PageContext from '@contexts/PageContext';
import { Container, ProductTile, ComparePopup } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';
import { compareProductSelector } from '@stores/compareProduct/selectors';
import { resetProductCompareCodeAction } from '@stores/compareProduct/actions';
import styles from './RelatedProducts.module.scss';

const RelatedProducts = ({
  title,
  data,
  containerClass,
  listClass,
  id,
  pageId,
  productType,
  componentIsAvailable,
  showTitle,
  componentTitle
}) => {
  const dispatch = useDispatch();
  const pageData = useContext(PageContext);
  const router = useRouter();
  const { locale } = router;
  const postPage = pageId === 27 || pageId === 28 ? true : false;

  const checkedProducts = useSelector(compareProductSelector);
  //Array of ready products to compare
  const compareProducts = checkedProducts
    .map((x) => {
      return pageData.RelatedProducts.find((y) => y.ProductCode == x);
    })
    .filter((f) => {
      return f ? f : false;
    });


  //show compare
  const showCompare = checkEnabled(data?.DisplayOptions, 'DisplayCompare');

  const showCompare2 = checkEnabled(data?.DisplayOptions, 'Compare');

  //has studio
  const hasStudio = checkEnabled(data?.DisplayOptions, 'Studio');


  //The section title on the page
  const sectionTitle = checkValue(data?.DisplayOptions, 'ComponentHeaderTitleText', locale);

  //featureText
  const featureTextEnabled = checkEnabled(data?.DisplayOptions, 'FeatureText');

  //featureTitle
  const featureTitleEnabled = checkEnabled(data?.DisplayOptions, 'FeatureTitle');

  // // headerTitle
  // const componentHeaderTitle = checkValue(data?.DisplayOptions, 'ComponentHeaderTitleText', locale);

  // check ImageAccessibilityInfo Enabled
  const imageAltEnabled = checkEnabled(data?.DisplayOptions, 'ImageAccessibilityInfo');

  //check SellPrice Enabled
  const sellPriceAvailable = checkEnabled(data?.DisplayOptions, 'SellPrice');

  //check SellPriceText Enabled
  const sellPriceTextAvailable = checkEnabled(data?.DisplayOptions, 'SellPriceText');

  //sellPriceText
  const sellPriceText = checkValue(data?.DisplayOptions, 'SellPriceText', locale);

  // check SmallDescription Enabled
  const smallDescriptionAvailable = checkEnabled(data?.DisplayOptions, 'SmallDescription');

  //check SubTitle Enabled
  const subTitleAvailable = checkEnabled(data?.DisplayOptions, 'SubTitle');

  //check TagDesc enabled
  const tagDescAvailable = checkEnabled(data?.DisplayOptions, 'DisplayPromotions');

  //check Title Enabled
  const TitleAvailable = checkEnabled(data?.DisplayOptions, 'Title');

  //check background Hero Enabled
  const hasbgHero = checkEnabled(data?.DisplayOptions, 'BackgroundHero');

  //check LinkOut
  const linkOutEnabled = checkEnabled(data?.DisplayOptions, 'LinkOut');

  useEffect(() => {
    if (!checkedProducts.every((e) => pageData.RelatedProducts.map((m) => m.ProductCode).includes(e))) {
      // dispatch(resetProductCompareCodeAction());
    }
  }, []);

  return (
    <section className={styles.Section} id={id}>
      {componentIsAvailable && (
        <Container className={classNames(styles.Container, containerClass)}>
          {title ||
            (showTitle && <h2 className={styles.Title}>{sectionTitle ? sectionTitle : title}</h2>)}
          <ul className={postPage ? classNames(styles.PostList, listClass) : classNames(styles.List, listClass)}>
            {pageData.RelatedProducts.map((item, index) => {
              let label;
              if (
                tagDescAvailable &&
                item.Tags?.some((tag) => (tag.TagCode === 'Promotion' || tag.TagCode) === 'Promotions')
              ) {
                label = item.Tags?.filter((tag) => tag.TagCode === 'Promotion' || tag.TagCode === 'Promotions').map(
                  (x) => x.Value
                );
              }

              return (
                <ProductTile
                  className={styles.Item}
                  key={index}
                  title={TitleAvailable ? item.Title : null}
                  subTitle={subTitleAvailable ? item.SubTitle : null}
                  price={item.MSRP}
                  features={item.QuickFeatures}
                  productCode={item.ProductCode}
                  productId={item.ProductId}
                  label={label}
                  compareCheckboxShow={showCompare || showCompare2}
                  smallDescription={smallDescriptionAvailable ? item.SmallDescription : null}
                  hasStudio={hasStudio}
                  sellPrice={sellPriceAvailable ? item.SellPrice : null}
                  sellPriceText={sellPriceTextAvailable ? sellPriceText : false}
                  featureTextEnabled={featureTextEnabled}
                  featureTitleEnabled={featureTitleEnabled}
                  imageAltEnabled={imageAltEnabled}
                  productType={item.ProductType}
                  hasbgHero={hasbgHero}
                  linkOutEnabled={linkOutEnabled}
                />
              );
            })}
            {checkedProducts && compareProducts && checkedProducts.length > 0 && compareProducts.length > 0 && (
              <ComparePopup data={compareProducts} btnStatus={checkedProducts.length > 2 ? false : true} />
            )}
          </ul>
        </Container>
      )}
    </section>
  );
};

export default RelatedProducts;
